import {
  BADGE_MAP,
  FENCED_YARD_BADGE_ID,
  COVID_VACCINATION_ID,
  POLICE_CHECK_ID,
  CALENDAR_UPDATE_THRESHOLD,
  defaultProfileImage,
} from '~/common/constants/search';
import { getSitterProfileUrl } from '~/common/utils/sitters';

import { transformBadgeDataIntoSeasonalUpsellBanner } from '../search/mappers';

import type { Badge, Favourite, GetFavoriteSittersRESTResponse } from './types';

export const transformFavouritesBadgeData = (
  badgeData: Array<Omit<Badge, 'icon'> & { icon: string }> = [],
  lastCalendarUpdate: number,
  providesRwbPerService: number[] = [],
  repeatGuests = 0
): Badge[] => {
  const filteredBadgeData = badgeData
    .filter(({ id }) => Object.keys(BADGE_MAP).includes(id.toString()))
    .map(({ id, name }) => {
      let badgeName = name;

      if (id === FENCED_YARD_BADGE_ID) {
        badgeName = 'Fenced yard';
      }

      if (id === POLICE_CHECK_ID) {
        badgeName = 'Police check';
      }

      if (id === COVID_VACCINATION_ID) {
        badgeName = 'COVID vaccinated';
      }

      return {
        icon: BADGE_MAP[id],
        id,
        name: badgeName,
      };
    });

  if (repeatGuests > 0) {
    filteredBadgeData.push({
      icon: BADGE_MAP[40],
      id: 40,
      name: repeatGuests === 1 ? `${repeatGuests} Repeat guest` : `${repeatGuests} Repeat guests`,
    });
  }

  if (lastCalendarUpdate && Date.now() - lastCalendarUpdate * 1000 <= CALENDAR_UPDATE_THRESHOLD) {
    filteredBadgeData.push({
      icon: BADGE_MAP[41],
      id: 41,
      name: 'Calendar recently updated',
    });
  }

  if (providesRwbPerService.length > 0) {
    filteredBadgeData.push({
      icon: BADGE_MAP[42],
      id: 42,
      name: 'Accepts weekly bookings',
    });
  }

  return filteredBadgeData;
};

export const mapFavouritesResults = ({ data }: GetFavoriteSittersRESTResponse): Favourite[] =>
  data.map(
    ({
      avatarV2,
      badges,
      city,
      id,
      latestReview,
      liked,
      name,
      rating,
      rewriteUrl,
      state,
      tagline,
      totalReviews,
      lastCalendarUpdate,
      providesRwbPerService,
      repeatGuests,
    }) => ({
      badges: transformFavouritesBadgeData(
        badges.data,
        lastCalendarUpdate,
        providesRwbPerService,
        repeatGuests
      ),
      id,
      location: { suburbName: city },
      name,
      profilePhoto: avatarV2,
      profileUrl: getSitterProfileUrl(city, rewriteUrl, state),
      rating: { numberOfReviews: totalReviews, score: rating },
      seasonalUpsellBanner: transformBadgeDataIntoSeasonalUpsellBanner(badges.data) ?? null,
      tagline,
      latestReview: latestReview?.data[0]
        ? { ...latestReview?.data[0], avatar: latestReview?.data[0].avatar || defaultProfileImage }
        : null,
      isSitterLiked: liked,
    })
  );
