/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import { LAST_UPDATED_FILTER_CITIES } from '~/api/graphql/search/constants';
import { SERVICE_TYPES } from '~/common/constants/app';
import {
  SERVICE_TYPE_BY_NAME_MAPPING,
  DEFAULT_SERVICE_JOB,
  DEFAULT_PRICE_RANGE,
  ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID,
  ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID,
  ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID,
  SITTERS_HOME_OPTIONS_APARTMENT_ID,
  SITTERS_HOME_OPTIONS_FENCED_ID,
  SITTERS_HOME_OPTIONS_HOUSE_ID,
} from '~/common/constants/search';
import { convertServiceSlugAndLocationToUrlStructure } from '~/common/utils/search';
import { getSitterProfileUrl } from '~/common/utils/sitters';

import type { SearchResults } from './types';
import type { BadgeIcons } from '@madpaws/design-system/dist/components/SearchResultsList/types';
import type {
  ServerDynamicContent,
  ServerSearchRESTResponse,
  NearbySuburbs,
  SearchResultsApiArgs,
  AdvancedFiltersApiArgs,
} from '~/api/graphql/search/SearchApi/types';
import type {
  Badge,
  DynamicSearchContent,
  SearchLink,
  SearchResultsArgs,
  SeasonalUpsellBanner,
} from '~/common/types/search';

const FENCED_YARD_BADGE_ID = 2;
const COVID_VACCINATION_ID = 33;
const EASTER_UPSELL_BADGE_ID = 15;
const WINTER_HOLIDAYS_BADGE_ID = 16;
const CHRISTMAS_UPSELL_BADGE_ID = 19;
const SPRING_UPSELL_BADGE_ID = 24;
const POLICE_CHECK_ID = 17;

const CALENDAR_UPDATE_THRESHOLD = 86400 * 7 * 1000;

const BADGE_MAP: { [key: number]: BadgeIcons } = {
  17: 'shieldStar',
  2: 'fence',
  33: 'shieldCheck',
  40: 'repeatClient',
  41: 'calendarCheck',
  42: 'repeat',
};

// NOTE: exported for unit testing purposes only
export const getDynamicSearchContent = (
  dynamicContent: ServerDynamicContent
): DynamicSearchContent | undefined => {
  if (!dynamicContent) {
    return undefined;
  }

  const {
    data: { header, service, session },
  } = dynamicContent;

  return {
    contentList: session,
    header,
    serviceName: service,
  };
};

// NOTE: exported for unit testing purposes only
export const generateStateShortForm = (str: string): string =>
  str
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');

export const checkLastUpdateEnabled = (city: string, state: string): boolean => {
  const stateShortForm = generateStateShortForm(state);

  const enabledCitiesOfState = LAST_UPDATED_FILTER_CITIES[stateShortForm] || [];
  return enabledCitiesOfState.includes(city) || enabledCitiesOfState.includes(city.toUpperCase());
};

// NOTE: exported for unit testing purposes only
export const transformBadgeData = (
  badgeData: Array<Omit<Badge, 'icon'> & { icon: string }> = [],
  lastCalendarUpdate: number,
  providesRwbPerService: number[],
  repeatGuests: number,
  service: string,
  _city: string,
  _state: string
): Badge[] => {
  const filteredBadgeData = badgeData
    .filter(({ id }) => Object.keys(BADGE_MAP).includes(id.toString()))
    .map(({ id, name }) => {
      let badgeName = name;

      if (id === FENCED_YARD_BADGE_ID) {
        badgeName = 'Fenced yard';
      }

      if (id === POLICE_CHECK_ID) {
        badgeName = 'Police check';
      }

      if (id === COVID_VACCINATION_ID) {
        badgeName = 'COVID vaccinated';
      }

      return {
        icon: BADGE_MAP[id],
        id,
        name: badgeName,
      };
    });

  if (repeatGuests > 0) {
    filteredBadgeData.push({
      icon: BADGE_MAP[40],
      id: 40,
      name: repeatGuests === 1 ? `${repeatGuests} Repeat guest` : `${repeatGuests} Repeat guests`,
    });
  }

  const lastCalendarUpdateDate = lastCalendarUpdate * 1000;
  if (Date.now() - lastCalendarUpdateDate <= CALENDAR_UPDATE_THRESHOLD) {
    filteredBadgeData.push({
      icon: BADGE_MAP[41],
      id: 41,
      name: 'Calendar recently updated',
    });
  }
  // TODO(WEB-2270): Revisit if we need this feature anymore
  // if (checkLastUpdateEnabled(city, state)) {
  //   filteredBadgeData.push({
  //     icon: BADGE_MAP[41],
  //     id: 41,
  //     name: `Calendar updated ${dayjs.unix(lastCalendarUpdate).fromNow()}`,
  //   });
  // }

  const [selectedService] = SERVICE_TYPES.filter((serviceType) => serviceType.name === service);
  const sitterProvidesRwbForService = providesRwbPerService.includes(selectedService.id);

  if (sitterProvidesRwbForService) {
    filteredBadgeData.push({
      icon: BADGE_MAP[42],
      id: 42,
      name: 'Accepts weekly bookings',
    });
  }

  return filteredBadgeData;
};

export const transformBadgeDataIntoSeasonalUpsellBanner = (
  badgeData: Array<Omit<Badge, 'icon'> & { icon: string }> = []
): SeasonalUpsellBanner | undefined => {
  const checkChristmasUpsellBanner = badgeData.some(({ id }) => id === CHRISTMAS_UPSELL_BADGE_ID);
  const checkEasterUpsellBanner = badgeData.some(({ id }) => id === EASTER_UPSELL_BADGE_ID);
  const checkWinterHolidaysBanner = badgeData.some(({ id }) => id === WINTER_HOLIDAYS_BADGE_ID);
  const checkSpringHolidaysBanner = badgeData.some(({ id }) => id === SPRING_UPSELL_BADGE_ID);

  const shouldChristmasUpsellBannerBeDisplayed =
    checkChristmasUpsellBanner &&
    !checkEasterUpsellBanner &&
    !checkWinterHolidaysBanner &&
    !checkSpringHolidaysBanner;

  if (shouldChristmasUpsellBannerBeDisplayed) {
    return {
      graphic: 'christmas',
      label: 'Available for the holidays',
    };
  }

  const shouldEasterUpsellBannerBeDisplayed =
    checkEasterUpsellBanner &&
    !checkChristmasUpsellBanner &&
    !checkWinterHolidaysBanner &&
    !checkSpringHolidaysBanner;

  if (shouldEasterUpsellBannerBeDisplayed) {
    return {
      label: 'Available Easter & Anzac Day',
    };
  }

  const shouldWinterHolidaysUpsellBannerBeDisplayed =
    checkWinterHolidaysBanner &&
    !checkChristmasUpsellBanner &&
    !checkEasterUpsellBanner &&
    !checkSpringHolidaysBanner;

  if (shouldWinterHolidaysUpsellBannerBeDisplayed) {
    return {
      graphic: 'winter',
      label: 'Available for July holidays',
    };
  }

  const shouldSpringHolidaysUpsellBannerBeDisplayed =
    checkSpringHolidaysBanner &&
    !checkWinterHolidaysBanner &&
    !checkChristmasUpsellBanner &&
    !checkEasterUpsellBanner;

  if (shouldSpringHolidaysUpsellBannerBeDisplayed) {
    return {
      graphic: 'spring',
      label: 'Available spring school break',
    };
  }

  return undefined;
};

export const transformNearbySuburbs = (
  nearbySuburbs: NearbySuburbs[],
  serviceSlug: string
): SearchLink[] => {
  const nearbySuburbsLinks = nearbySuburbs.map(({ suburb, state }) => {
    const serviceJob = SERVICE_TYPE_BY_NAME_MAPPING[serviceSlug]?.serviceJob || DEFAULT_SERVICE_JOB;

    return {
      href: convertServiceSlugAndLocationToUrlStructure(serviceSlug, suburb, state),
      label: `${serviceJob} ${suburb} ${state}`,
    };
  });

  return nearbySuburbsLinks;
};

// NOTE: exported for unit testing purposes only
export const mapPrice = (
  priceRaw: string | undefined
): {
  priceMax?: number;
  priceMin?: number;
} => {
  const price =
    priceRaw &&
    priceRaw.split(',').length === 2 &&
    priceRaw
      .split(',')
      .every((priceItem) => !!parseInt(priceItem, 10) || parseInt(priceItem, 10) === 0)
      ? priceRaw.split(',')
      : DEFAULT_PRICE_RANGE;

  const [priceMin, priceMax] = price;

  return {
    priceMax: parseInt(priceMax, 10),
    priceMin: parseInt(priceMin, 10),
  };
};

// NOTE: exported for unit testing purposes only
export const mapSitterOptions = (
  sitterOptionsRaw: string | undefined
): Omit<AdvancedFiltersApiArgs, 'priceMin' | 'priceMax'> | undefined => {
  if (!sitterOptionsRaw) {
    return;
  }
  const sitterOptions = sitterOptionsRaw?.split(',');

  const accommodationTypeIds: { [key: string]: number } = {
    [SITTERS_HOME_OPTIONS_APARTMENT_ID]: 4,
    [SITTERS_HOME_OPTIONS_HOUSE_ID]: 5,
  };

  const accommodationTypes: string = sitterOptions
    .filter(
      (type) => type === SITTERS_HOME_OPTIONS_HOUSE_ID || type === SITTERS_HOME_OPTIONS_APARTMENT_ID
    )
    .map((accType) => accommodationTypeIds[accType])
    .join(',');

  return {
    accommodationTypes,
    backyardFenced: sitterOptions.includes(SITTERS_HOME_OPTIONS_FENCED_ID),
    noApartment: sitterOptions.includes(SITTERS_HOME_OPTIONS_HOUSE_ID),
    noDogs: sitterOptions.includes(PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID),
    noCats: sitterOptions.includes(PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID),
    noChildren: sitterOptions.includes(PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID),
    policeCheck: sitterOptions.includes(ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID),
    flash: sitterOptions.includes(ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID),
    providesTravelForPet: sitterOptions.includes(ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID),
  };
};

export const mapSearchResultsArgsToApiQuery = (
  searchQuery: SearchResultsArgs
): SearchResultsApiArgs => {
  const { price, sitterOptions, ...restQueryArgs } = searchQuery;
  const { priceMax, priceMin } = mapPrice(price);
  const mappedSitterOptions = mapSitterOptions(sitterOptions);

  return {
    priceMax,
    priceMin,
    ...mappedSitterOptions,
    ...restQueryArgs,
  };
};

export const mapSearchResults = (
  {
    data,
    meta: {
      dynamicContent,
      dynamicMeta,
      nearSuburbs,
      ownersCount,
      pagination: { currentPage, perPage, total },
      aggregateRating: {
        data: {
          priceRange: {
            data: { code, maxPrice, minPrice },
          },
          ratingValue,
          totalReviews: ratingCount,
        },
      },
      searchVersion,
      serviceType,
    },
  }: ServerSearchRESTResponse,
  serviceSlug: string
): SearchResults => ({
  results: data.map(
    ({
      avatarV2,
      badges,
      basePrice,
      city,
      distance,
      headline,
      id,
      lastCalendarUpdate,
      name,
      providesRwbPerService,
      rating,
      repeatGuests,
      rewriteUrl,
      totalReviews,
      state,
      latestReview,
      liked,
    }) => ({
      badges: transformBadgeData(
        badges.data,
        lastCalendarUpdate,
        providesRwbPerService,
        repeatGuests,
        serviceType,
        city,
        state
      ),
      id,
      location: { distanceFromSearchAddress: distance, suburbName: city },
      name,
      basePrice,
      // consume avatarV2 image link instead of avatar
      // because a new version has all necessary
      // Cloudinary transformation parameters
      profilePhoto: avatarV2,
      profileUrl: getSitterProfileUrl(city, rewriteUrl, state),
      rating: { numberOfReviews: totalReviews, score: rating },
      seasonalUpsellBanner: transformBadgeDataIntoSeasonalUpsellBanner(badges.data) ?? null,
      tagline: headline,
      latestReview: latestReview?.data[0] || null,
      isSitterLiked: liked,
    })
  ),
  pageInfo: {
    offset: (currentPage - 1) * perPage,
    size: perPage,
    total,
  },
  metaInfo: {
    aggregateRating: {
      currencyCode: code,
      maxPrice,
      minPrice,
      offerCount: total,
      ownersCount,
      ratingCount,
      ratingValue,
    },
    dynamicSearchContent: getDynamicSearchContent(dynamicContent),
    metaDescription: dynamicMeta?.data?.text,
    nearSuburbs: transformNearbySuburbs(nearSuburbs.data, serviceSlug),
    searchVersion,
    serviceType,
  },
});
