import { toast } from 'react-toastify';

import { mapFavouritesResults } from './mappers';
import { RestApi } from '../restApi';

import type {
  DeleteSitterProfileResponse,
  Favourite,
  GetFavoriteSittersRESTResponse,
  GetSitterDetailsRestResponse,
  UpdateFavoriteSitterResponse,
  UpdateSitterHouseholdRequest,
  UpdateSitterHouseholdResponse,
} from './types';
import type { AxiosError, AxiosResponse } from 'axios';

export class SitterApi extends RestApi {
  static async deleteSitterProfile(): Promise<DeleteSitterProfileResponse | null> {
    try {
      const response: AxiosResponse<DeleteSitterProfileResponse> = await this.woofApi.delete(
        '/v1/sitters'
      );

      return { ...response.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  static async getFavoriteSitters(): Promise<Favourite[] | null> {
    try {
      const response: AxiosResponse<GetFavoriteSittersRESTResponse> = await this.woofApi.get(
        '/v1/sitters/liked'
      );

      return mapFavouritesResults(response.data);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  static async getSitterDetails(): Promise<GetSitterDetailsRestResponse['data'] | null> {
    try {
      const response: AxiosResponse<GetSitterDetailsRestResponse> = await this.woofApi.get(
        '/v1/sitters'
      );

      return { ...response.data.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  static async updateFavoriteSitter(
    id: number,
    isFavorite: boolean
  ): Promise<UpdateFavoriteSitterResponse | null> {
    try {
      const response: AxiosResponse<UpdateFavoriteSitterResponse> = await this.woofApi.post(
        `/v1/sitters/${id}/${isFavorite ? 'like' : 'unlike'}`
      );

      return { ...response.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  static async updateSitterHousehold(
    values: UpdateSitterHouseholdRequest
  ): Promise<UpdateSitterHouseholdResponse | null> {
    try {
      const response: AxiosResponse<UpdateSitterHouseholdResponse> = await this.woofApi.patch(
        '/v1/sitters/household',
        values
      );

      toast.success(response.data.message);

      return null;
    } catch (error) {
      toast.error('Could not update household details at this time');

      return this.handleError(error as AxiosError);
    }
  }
}
